import React, { useState } from "react";
import { useGetChangeBatteryQuery } from "../../shared/api/api";

const BatteryChange = () => {
    const { data } = useGetChangeBatteryQuery();
    const [sortedData, setSortedData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });

    // Сортування даних
    const sortData = (key) => {
        if (!data || data.length === 0) return;

        const direction = sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
        const sorted = [...data].sort((a, b) => {
            if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
            if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
            return 0;
        });

        setSortedData(sorted);
        setSortConfig({ key, direction });
    };

    // Використовуємо sortedData, якщо є, інакше дані з API
    const displayData = sortedData.length > 0 ? sortedData : data;

    const tableStyle = {
        width: "100%",
        borderCollapse: "collapse",
        marginTop: "20px",
    };

    const thTdStyle = {
        border: "1px solid #ddd",
        padding: "8px",
        textAlign: "center",
    };

    const thStyle = {
        backgroundColor: "#f4f4f4",
        color: "#333",
        fontWeight: "bold",
        cursor: "pointer",
    };

    const containerStyle = {
        width: "90%",
        margin: "20px auto",
        textAlign: "center",
    };

    return (
        <div style={containerStyle}>
            <h2>Battery Change Logs</h2>

            {/* Таблиця */}
            <table style={tableStyle}>
                <thead>
                <tr>
                    <th
                        style={thStyle}
                        onClick={() => sortData("id")}
                    >
                        ID {sortConfig.key === "id" ? (sortConfig.direction === "asc" ? "▲" : "▼") : ""}
                    </th>
                    <th
                        style={thStyle}
                        onClick={() => sortData("post")}
                    >
                        Post {sortConfig.key === "post" ? (sortConfig.direction === "asc" ? "▲" : "▼") : ""}
                    </th>
                    <th
                        style={thStyle}
                        onClick={() => sortData("battery_type")}
                    >
                        Battery Type {sortConfig.key === "battery_type" ? (sortConfig.direction === "asc" ? "▲" : "▼") : ""}
                    </th>
                    <th
                        style={thStyle}
                        onClick={() => sortData("current_battery")}
                    >
                        New Battery {sortConfig.key === "current_battery" ? (sortConfig.direction === "asc" ? "▲" : "▼") : ""}
                    </th>
                    <th
                        style={thStyle}
                        onClick={() => sortData("battery_replaced")}
                    >
                        Old Battery {sortConfig.key === "battery_replaced" ? (sortConfig.direction === "asc" ? "▲" : "▼") : ""}
                    </th>
                    <th
                        style={thStyle}
                        onClick={() => sortData("timestamp")}
                    >
                        Timestamp {sortConfig.key === "timestamp" ? (sortConfig.direction === "asc" ? "▲" : "▼") : ""}
                    </th>
                </tr>
                </thead>
                <tbody>
                {displayData &&
                    displayData.map((item) => (
                        <tr key={item.id}>
                            <td style={thTdStyle}>{item.id}</td>
                            <td style={thTdStyle}>{item.post}</td>
                            <td style={thTdStyle}>{item.battery_type}</td>
                            <td style={thTdStyle}>{item.current_battery}</td>
                            <td style={thTdStyle}>{item.battery_replaced}</td>
                            <td style={thTdStyle}>{new Date(item.timestamp).toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default BatteryChange;
