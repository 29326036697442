import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Switch from '@mui/joy/Switch';
import { useUpdateRelayStatusMutation } from "../api/api";

const PostRer = ({ name, electricityData, relayData }) => {
    // Ініціалізуємо стан перемикачів як boolean
    const [checked, setChecked] = React.useState(
        relayData.reduce((acc, relay) => {
            acc[relay.works_on] = relay.status === 1;
            return acc;
        }, {})
    );


    const [updateRelayStatus] = useUpdateRelayStatusMutation();

    // Оновлюємо стан перемикача для відповідного works_on і відправляємо запит
    const handleSwitchChange = (works_on, post_name, relay_number) => async (event) => {
        const newState = event.target.checked; // Новий стан як boolean (інверсія логіки)

        setChecked((prevState) => ({
            ...prevState,
            [works_on]: !newState, // Ставимо протилежне значення (щоб true означало вимкнений)
        }));

        // Відправляємо запит на сервер
        try {
            await updateRelayStatus({
                post_name: post_name,
                relay_number: relay_number,
                status: !newState,  // Відправляємо реверсований стан на сервер
            });
            console.log(`Successfully updated ${post_name} relay: ${relay_number}`);
        } catch (error) {
            console.error(`Error updating relay ${relay_number}:`, error);
        }
    };

    return (
        <Paper
            elevation={3}
            sx={{
                padding: '24px',
                borderRadius: '8px',
                backgroundColor: 'background.paper',
                marginBottom: '16px',
                overflow: 'hidden',
            }}
        >
            <Typography variant="h6" component="div" align="center" sx={{ marginBottom: '16px', color: 'text.primary' }}>
                {name}
            </Typography>

            <Box sx={{ color: 'text.secondary' }}>
                <Typography variant="body1">
                    Voltage: <Box component="span" sx={{ fontWeight: 'bold' }}>{Math.abs(electricityData.voltage).toFixed(3)} V</Box>
                </Typography>
                {electricityData.current !==0 && <Typography variant="body1">
                    Current: <Box component="span" sx={{ fontWeight: 'bold' }}>{Math.abs(electricityData.current).toFixed(3)} А</Box>
                </Typography>
                }
                {electricityData.power !==0 && <Typography variant="body1">
                    Power: <Box component="span" sx={{ fontWeight: 'bold' }}>{Math.abs(electricityData.power).toFixed(3)} W</Box>
                </Typography>
                }
                {electricityData.temperature !== undefined && (
                    <Typography variant="body1">
                        Temperature: <Box component="span" sx={{ fontWeight: 'bold' }}>{Math.abs(electricityData.temperature).toFixed(1)} °C</Box>
                    </Typography>
                )}
            </Box>

            {/* Виводимо перемикачі, якщо дані для реле є */}
            {relayData.length > 0 && relayData.map((relay) => (
                <FormControl
                    key={relay.id}
                    orientation="horizontal"
                    sx={{ width: 300, justifyContent: 'space-between', marginTop: '16px' }}
                >
                    <FormLabel>{`Power ${relay.works_on}`}</FormLabel>
                    <Switch
                        checked={!checked[relay.works_on]} // Інвертуємо для відображення: false (вмикається), true (вимикається)
                        onChange={handleSwitchChange(relay.works_on, relay.post_name, relay.relay_number)} // Передаємо works_on, post_name та relay_number
                        color={!checked[relay.works_on] ? 'success' : 'neutral'} // Якщо значення false, показуємо як увімкнене (зелений)
                        variant={!checked[relay.works_on] ? 'solid' : 'outlined'}
                        endDecorator={!checked[relay.works_on] ? 'On' : 'Off'}
                        slotProps={{
                            endDecorator: {},
                        }}
                    />
                </FormControl>
            ))}
        </Paper>
    );
};

export default PostRer;
