import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Layout from "../../pages/common/Layout";
import Electricity from "../../pages/electricity/Electricity";
import BatteryChange from "../../pages/battery/BatteryChange";
import Trends from "../../pages/trends/Trends";
import Generator from "../../pages/generator/Generator";

const RootNavigator = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Electricity />} />
                    <Route path="trends" element={<Trends />} />
                    <Route path="battery-change" element={<BatteryChange />} />
                    <Route path="generator" element={<Generator />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default RootNavigator;
