import { AppBar, Toolbar, Button, IconButton, Badge, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import Box from "@mui/joy/Box";

const Menu = () => {
    return (
        <AppBar position="static">
            <Toolbar sx={{ alignItems: 'center' }}>
                {/* Логотип та кнопки */}
                <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                    <Box
                        component="img"
                        src="/13th_khartia.png"
                        alt="Logo"
                        sx={{
                            height: '50px',
                            marginRight: '16px',
                            display: { xs: 'none', sm: 'block' } // Логотип прихований на мобільних (xs)
                        }}
                    />
                    <Button color="inherit" component={Link} to="/">
                        Electricity
                    </Button>
                    <Button color="inherit" component={Link} to="/trends">
                        Trends
                    </Button>
                    <Button color="inherit" component={Link} to="/battery-change">
                        Battery
                    </Button>
                    <Button color="inherit" component={Link} to="/generator">
                        Generator
                    </Button>
                </Box>

                {/* Іконки */}
                <MenuItem sx={{ display: { xs: 'none', sm: 'block' } }}> {/* Приховано на мобільних (xs) */}
                    <IconButton
                        size="large"
                        aria-label="settings"
                        color="inherit"
                    >
                        <SettingsIcon />
                    </IconButton>
                </MenuItem>
                <MenuItem sx={{ display: { xs: 'none', sm: 'block' } }}> {/* Приховано на мобільних (xs) */}
                    <IconButton
                        size="large"
                        aria-label="show 17 new notifications"
                        color="inherit"
                    >
                        <Badge badgeContent={17} color="error">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                </MenuItem>
            </Toolbar>
        </AppBar>
    );
};

export default Menu;
